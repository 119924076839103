import React from "react";
import { Link, useLocation } from "react-router-dom";

import { AiOutlineMenu } from "react-icons/ai";

import { useAuthenticated } from "../../store/slices/auth";
import logo from "../../assets/images/akn.svg";

const Nav = () => {
  const isAuthenticated = useAuthenticated();
  const location = useLocation();
  // console.log(location.pathname);

  return (
    <nav className="navbar navbar-expand-lg navbar-light foi-navbar">
      <Link to="/">
        <span className="navbar-brand">
          <img
            src={logo}
            alt="Aaja ko Nepal"
            className="img-fluid"
            height="80px"
            width="80px"
          />
        </span>
      </Link>

      <button
        className="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavId"
        aria-controls="collapsibleNavId"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {/* <span className="navbar-toggler-icon"></span> */}
        <AiOutlineMenu
          color={location.pathname === "/" ? "white" : "black"}
          fontSize={30}
        />
      </button>
      <div className="collapse navbar-collapse" id="collapsibleNavId">
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          <li className="nav-item">
            <Link to="/">
              <span className="nav-link text-decoration-none">Home</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/play">
              <span className="nav-link text-decoration-none">Play</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/explore">
              <span className="nav-link text-decoration-none">Explore</span>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/about">
              <span className="nav-link text-decoration-none">About</span>
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="features.html">
              Features
            </a>
          </li> */}
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="pagesMenu"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              Pages
            </a>
            <div className="dropdown-menu" aria-labelledby="pagesMenu">
              <a className="dropdown-item" href="blog.html">
                Blog
              </a>
              <a className="dropdown-item" href="login.html">
                Login
              </a>
              <a className="dropdown-item" href="register.html">
                Register
              </a>
              <a className="dropdown-item" href="faq.html">
                FAQ
              </a>
              <Link to="/404">
                <span className="dropdown-item">404</span>
              </Link>
              <a className="dropdown-item" href="careers.html">
                Careers
              </a>
              <a className="dropdown-item" href="blog-single.html">
                Single blog
              </a>
              <Link to="/privacy">
                <span className="dropdown-item">Privacy policy</span>
              </Link>
            </div>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="contact.html">
              contact
            </a>
          </li> */}
        </ul>

        <ul className="navbar-nav mt-2 mt-lg-0">
          {isAuthenticated ? (
            <li className="nav-item mr-2 mb-3 mb-lg-0">
              <Link className="btn btn-secondary" to="/app">
                Dashboard
              </Link>
            </li>
          ) : (
            <>
              <li className="nav-item mr-2 mb-3 mb-lg-0">
                <Link className="btn btn-secondary" to="/register">
                  Sign up
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/login">
                  <span className="btn btn-secondary">Login</span>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
