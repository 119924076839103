import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import useSWR from "swr";
import { Select } from "@mantine/core";

import axiosService, { fetcher } from "../../utils/axios";

// const categoryFetcher = (url) => {
//   return axiosService.get(url).then((res) => {
//     let data = [];
//     res.data.map((catData) => {
//       data.push({ value: catData.id, label: catData.name });
//     });
//     return data;
//   });
// };

const UpdateCategory = ({ url, closeModal, mutate }) => {
  const getCategoriesUrl = `/categories/all/list-category/`;
  const history = useHistory();
  const getInitialData = useSWR(url, fetcher);
  const getCategoryData = useSWR(getCategoriesUrl, fetcher);
  const initialData = getInitialData?.data;
  const [categoryData, setCategoryData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const categories = getCategoryData?.data;

  useEffect(() => {
    setActiveCategory(categories && categories[0]);
  }, [categories]);

  useEffect(() => {
    if (!activeCategory) return;
    let activeOptions = [];
    categories?.forEach((option) => {
      // console.log(option)
      activeOptions.push({
        label: option.name,
        value: option.id.toString(),
      });
    });
    setCategoryData(activeOptions);
  }, [activeCategory, categories]);

  const handleUpdate = (data) => {
    // Validating if the action URL is empty
    if (!url || url === "") return;
    axiosService
      .patch(url, data)
      .then((res) => {
        // console.log(res);
        mutate();
        toast.success("Category Updated!");
        closeModal();
      })
      .catch((err) => {
        // console.log(err);
        let error_dict = err.response.data;
        for (let e in error_dict) {
          // console.log(e, error_dict[e][0]);
          toast.error(error_dict[e][0]);
        }
      });
  };

  const UpdateSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: initialData?.name ?? "",
      parent: initialData?.parent?.toString() ?? "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // setLoading(true);
      handleUpdate(values);
      //   console.log(values);
    },
    validationSchema: UpdateSchema,
  });

  useEffect(() => {
    //   Validating User ID
    if (getInitialData?.error?.response.status === 404) {
      history.push("/app");
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="my-3">
      <div className="form-group">
        <label htmlFor="name">
          Category Name <sup>*</sup>
        </label>
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder="Category Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name ? (
          <p className="text-warning">{formik.errors.username} </p>
        ) : null}
      </div>

      <div className="form-group">
        {categoryData.length > 0 && (
          <Select
            label="Parent Category"
            placeholder="Pick One"
            data={categoryData}
            value={formik.values.parent}
            onChange={(e) => formik.setFieldValue("parent", e)}
            nothingFound="No options"
            clearable
          />
        )}
      </div>

      <button className="btn btn-warning btn-auth-submit mx-1" type="submit">
        Save Changes
      </button>
      <button className="btn btn-light mx-1" type="button" onClick={closeModal}>
        Cancel
      </button>
    </form>
  );
};

export default UpdateCategory;
