import React from "react";

import Footer from "../components/footer/footer";
import Nav from "../components/header/nav";
// import "../assets/vendors/fontawesome-free/css/all.min.css";

const BaseTheme = React.lazy(() => import("./BaseTheme"));

const BaseLayout = ({ children }) => {

  return (
    // <React.Suspense fallback={<></>}>
    <div className="App bstyle">
      {/* <Helmet>
        <style>{`${style}`}</style>
      </Helmet> */}
      <BaseTheme />
      <div className="container pt-3">
        <Nav />
      </div>
      <>{children}</>
      <Footer />
    </div>
    // </React.Suspense>
  );
};

export default BaseLayout;
