import { Text } from "@mantine/core";
import styled from "@emotion/styled";

const TextLink = styled(Text)`
  cursor: pointer;

  &:hover {
    color: #ce2021;
  }
`;

const TextLinkNormal = styled.span`
  cursor: pointer;

  &:hover {
    color: #ce2021;
  }
`;

const ErrorMsg = styled.p`
  color: #fea204;
`;

export { TextLink, TextLinkNormal, ErrorMsg };
