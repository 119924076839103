import React from "react";
import { Route } from "react-router-dom";

import useDocTitle from "../../utils/useDocTitle";
import BaseLayout from "../../layouts/Base";

const BaseRoute = ({ component: Component, docTitle, ...rest }) => {
  let title = "Aaja ko Nepal";
  if (docTitle) {
    title = `AKN | ${docTitle}`;
  }
  useDocTitle(title);

  return (
    <Route
      {...rest}
      render={(props) => (
        <BaseLayout>
          <Component {...props} />
        </BaseLayout>
      )}
    />
  );
};

export default BaseRoute;
