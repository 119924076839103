import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { AiOutlineUser } from "react-icons/ai";
import { CgProfile, CgLogOut } from "react-icons/cg";
import { Avatar } from "@mantine/core";

import authSlice from "../../../store/slices/auth";

const UserDropdown = () => {
  const profileImg = useSelector((state) => state.auth.account?.profile_image);
  const username = useSelector((state) => state.auth.account?.email);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(authSlice.actions.logout());
    history.push("/login");
  };

  return (
    <li className="nav-item nav-profile dropdown border-0">
      <a
        className="nav-link dropdown-toggle d-flex align-items-center"
        id="profileDropdown"
        href="/"
        data-toggle="dropdown"
      >
        {profileImg ? (
          <Avatar size="sm" radius="xl" src={profileImg} alt={username} />
        ) : (
          // <img className="nav-profile-img mr-2" alt="" src={profileImg} />
          <AiOutlineUser fontSize={25} />
        )}
        <span className="profile-name text-truncate ml-2">{username}</span>
      </a>
      <div
        className="dropdown-menu navbar-dropdown w-100"
        aria-labelledby="profileDropdown"
      >
        {/* <a className="dropdown-item" href="/">
          <i className="mdi mdi-cached mr-2 text-success"></i> Activity Log
        </a> */}
        <Link to="/app/my-profile/" className="dropdown-item">
          <CgProfile className="mr-2 text-success" /> My Profile
        </Link>
        <a className="dropdown-item" href="/" onClick={handleLogout}>
          <CgLogOut className="mr-2 text-primary" /> Signout
        </a>
      </div>
    </li>
  );
};

export default UserDropdown;
