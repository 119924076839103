import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import UBaseLayout from "../../dashboard/layouts/UBase";

import { useAuthenticated } from "../../store/slices/auth";
import useDocTitle from "../../utils/useDocTitle";
import axiosService from "../../utils/axios";
import authSlice from "../../store/slices/auth";

const UBaseRoute = ({
  component: Component,
  docTitle,
  permissions,
  ...rest
}) => {
  const userAuthenticated = useAuthenticated();
  // let hasAccess = useRef(true);
  const [hasAccess, setHasAccess] = useState(true);
  const currentUserData = useSelector((state) => state.auth.account);
  const dispatch = useDispatch();
  const history = useHistory();
  // const currentUserRole = useSelector((state) => state.auth.account.role);

  useEffect(() => {
    // Updating the user data if it has been tampered or edited manually somehow
    axiosService
      .get("/core/validate-logged-in-user/")
      .then((res) => {
        const validUserData = res.data;
        try {
          if (
            JSON.stringify(validUserData) !== JSON.stringify(currentUserData)
          ) {
            // console.log("User data Tampered ❎");
            dispatch(authSlice.actions.setAccount(validUserData));
            // console.log("Valid Data Updated 🚀");
          }
        } catch (error) {
          setHasAccess(false);
        }

        if (permissions) {
          // hasAccess.current = permissions.includes(currentUserData.role);
          // console.log(permissions.includes(currentUserData.role));
          setHasAccess(permissions.includes(currentUserData.role));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          dispatch(authSlice.actions.logout());
          history.push("/login");
        }
      });
  });

  // console.log(children);
  let title = "Aaja ko Nepal";
  if (docTitle) {
    title = `AKN | ${docTitle}`;
  }
  useDocTitle(title);
  return userAuthenticated && hasAccess ? (
    // console.log("Authenticated User")
    <Route
      {...rest}
      render={(props) => (
        <UBaseLayout>
          <Component {...props} />
        </UBaseLayout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          from: rest.location,
        },
      }}
    />
    // console.log("Redirect to Login")
  );
};

export default UBaseRoute;
