import React from "react";

import { FcAnswers } from "react-icons/fc";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { Divider } from "@mantine/core";
import QuizQuestions from "./QuizQuestions";

const PublicCompResult = ({ competitions }) => {
  // console.log(polls);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <div className="container pb-5 pt-4 px-3 px-sm-0">
        <h3 className="text-primary text-center">
          Latest Released Results for Competition <FcAnswers />
        </h3>

        <Divider my="sm" />

        {competitions.length > 0 &&
          competitions.map((competition, cindex) => (
            <div
              key={cindex}
              className={`row px-3 px-sm-0 ${
                competitions.length > 1 &&
                cindex + 1 < competitions.length &&
                "mb-5"
              }`}
            >
              <div className="col-lg-12">
                <h4>
                  <AiOutlineDoubleRight fontSize={15} className="mr-2" />
                  <code>Competition:</code> {competition.title}
                </h4>
              </div>
              <div className="col-lg-8 mx-auto">
                <QuizQuestions
                  questions={competition?.questions}
                  settings={sliderSettings}
                />
                {/* <Slider {...sliderSettings} className="mx-4">
                  {competition?.questions?.map((question, index) => (
                    <Card shadow="sm" className="px-3" key={index}>
                      <div className="d-flex align-items-center mb-3">
                        <BsQuestionDiamondFill
                          className="mr-2 text-danger"
                          fontSize={25}
                        />
                        <Text size="lg" className="font-weight-bold">
                          {question?.text}
                        </Text>
                      </div>

                      <List
                        spacing="xs"
                        size="sm"
                        center
                        icon={
                          <ThemeIcon color="gray" size={24} radius="xl">
                            <BiBlock size={16} />
                          </ThemeIcon>
                        }
                      >
                        {question?.options?.map((option, index) => (
                          <List.Item
                            icon={
                              option.is_correct && (
                                <ThemeIcon color="teal" size={24} radius="xl">
                                  <CircleCheck size={16} />
                                </ThemeIcon>
                              )
                            }
                            key={index}
                          >
                            {option.text}
                          </List.Item>
                        ))}
                      </List>
                    </Card>
                  ))}
                </Slider> */}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default PublicCompResult;
