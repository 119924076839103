import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ catData, color }) => {
  return (
    <>
      <div className="col-lg-6 my-2 my-sm-1">
        <Link to={catData.detail_url} className="text-decoration-none">
          <div
            className={`card pricing-card border-${color} py-1 hover-primary`}
          >
            <div className="card-body">
              <h3 className="mb-1">{catData.name}</h3>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default CategoryCard;
