import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Select } from "@mantine/core";
// import useSWR, { useSWRConfig } from "swr";

import axiosService from "../../utils/axios";

const AddCategory = ({ closeModal, mutate }) => {
  const getCategoriesUrl = `/categories/all/list-category`;
  // const getCategories = useSWR(getCategoriesUrl, fetcher);
  const [categoryData, setCategoryData] = useState([]);
  // const [parentData, setParentData] = useState('');

  useEffect(() => {
    axiosService.get(getCategoriesUrl).then((res) => {
      let data = [];
      res.data.forEach((catData) => {
        data.push({ value: catData.id, label: catData.name });
      });
      setCategoryData(data);
    });
  }, [getCategoriesUrl, setCategoryData]);

  // const handleParentOnChange = (e) => {
  //   formik.values.parent = e;
  // };

  const handleAdd = (data, { resetForm }) => {
    axiosService
      .post(`/categories/category/`, data)
      .then((res) => {
        // console.log(res);
        mutate();
        toast.success("Category Added!");
        closeModal();
        resetForm();
      })
      .catch((err) => {
        let error_dict = err.response.data;
        for (let e in error_dict) {
          // console.log(e, error_dict[e][0]);
          toast.error(error_dict[e][0]);
        }
        // setMessage(err.response.data);
        // console.log(message);
      });
  };

  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      parent: "",
    },
    enableReinitialize: true,
    onSubmit: (values, resetForm) => {
      // setLoading(true);
      handleAdd(values, resetForm);
      //   console.log(values);
    },
    validationSchema: CategorySchema,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="forms-sample my-3">
      <div className="form-group">
        <label htmlFor="name">
          Category Name <sup>*</sup>
        </label>
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder="Category Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.name && formik.errors.name ? (
          <p className="text-warning">{formik.errors.name} </p>
        ) : null}
      </div>

      <div className="form-group">
        <Select
          label="Parent Category"
          placeholder="Pick one"
          data={categoryData}
          onChange={(e) => formik.setFieldValue("parent", e)}
          searchable
          nothingFound="No options"
        />
      </div>

      <button className="btn btn-warning btn-auth-submit mx-1" type="submit">
        Add Category
      </button>

      <button className="btn btn-light mx-1" type="button" onClick={closeModal}>
        Cancel
      </button>
    </form>
  );
};

export default AddCategory;
