import React from "react";

import { Card, List, ThemeIcon, Text } from "@mantine/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BsQuestionDiamondFill,
  BsFillCheckCircleFill as CircleCheck,
} from "react-icons/bs";
import { BiBlock } from "react-icons/bi";
import { ImCross, ImCheckmark } from "react-icons/im";

import {
  CarouselArrowLeft,
  CarouselArrowRight,
} from "../../dashboard/components/misc/Buttons";

const ResultSlider = ({ answers }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CarouselArrowLeft />,
    nextArrow: <CarouselArrowRight />,
    // arrows: false
  };
  return (
    <>
      <div className="col-lg-11 mx-auto">
        <Slider {...settings}>
          {answers.map((answer, indexTop) => (
            <Card key={indexTop} shadow="sm" className="px-3 my-3">
              <div className="d-flex align-items-center mb-3">
                <BsQuestionDiamondFill
                  className="mr-2 text-danger"
                  fontSize={25}
                />
                <Text size="lg" className="font-weight-bold">
                  {answer.question.text}
                </Text>
              </div>

              <List spacing="xs" size="sm" center>
                {answer.question.options.map((option, index) => (
                  <List.Item
                    icon={
                      option.is_correct ? (
                        <ThemeIcon color="teal" size={24} radius="xl">
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      ) : (
                        <>
                          <ThemeIcon color="gray" size={24} radius="xl">
                            <BiBlock size={16} />
                          </ThemeIcon>
                        </>
                      )
                    }
                    key={index}
                  >
                    {option.text}

                    {option.id === answer.option &&
                      (option.is_correct ? (
                        <ImCheckmark className="text-success ml-3" />
                      ) : (
                        <ImCross className="text-danger ml-3" />
                      ))}
                  </List.Item>
                ))}
              </List>
            </Card>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default ResultSlider;
