import { lazy, Suspense } from "react";
// import logo from "./logo.svg";
import "./App.css";
import Index from "./pages/Index";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Login from "./pages/Login";

// import Dashboard from "./dashboard/pages/home";
import ButtonLayouts from "./dashboard/pages/ButtonLayouts";
import DropdownLayouts from "./dashboard/pages/DropdownLayouts";
import LoaderSuspense from "./dashboard/components/misc/LoaderSuspense";

import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import publicRoutes from "./routes/public";
import protectedRoutes from "./routes/protected";
import BaseRoute from "./routes/base/BaseRoute";
import UBaseRoute from "./routes/base/UBaseRoute";

import { Toaster } from "react-hot-toast";

const Page404 = lazy(() => import("./pages/404"));
const PlayQuiz = lazy(() => import("./pages/quiz/PlayQuiz"));

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Toaster />
        <Suspense fallback={<LoaderSuspense />}>
          <Router>
            <Switch>
              <Route exact path="/" component={Index} />
              {/* <Route exact path="/login" component={Login} /> */}

              <Route exact path="/buttons" component={ButtonLayouts} />
              <Route exact path="/dropdowns" component={DropdownLayouts} />
              {publicRoutes.map((route, index) => {
                return (
                  <BaseRoute
                    key={index}
                    exact={route.exact ?? false}
                    path={route.path}
                    component={route.component}
                    docTitle={route.title}
                  />
                );
              })}

              <Route exact path="/play/:quizSlug" component={PlayQuiz} />

              {protectedRoutes.map((route, index) => {
                return (
                  <UBaseRoute
                    key={index}
                    exact={route.exact ?? false}
                    path={route.path}
                    component={route.component}
                    docTitle={route.title}
                    permissions={route?.permission}
                  />
                );
              })}

              <Route>
                <Page404 />
              </Route>
            </Switch>
          </Router>
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
