import React from "react";
import { useSelector } from "react-redux";

const withRole = (roles) => (Component) => (props) => {
  const userRole = useSelector((state) => state.auth.account.role);
  if (roles.includes(userRole)) {
    return <Component {...props} />;
  }
  return null;
};

export default withRole;

export const withAdminRole = withRole(["Admin"]);
export const withManagerRole = withRole(["Manager"]);
export const withPlayerRole = withRole(["Player"]);
export const withManagmentRole = withRole(["Admin", "Manager"]);
export const withUserRole = withRole(["Admin", "Manager", "Player"]);

// A HOC to handle user role based UI components 👆
