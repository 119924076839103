import { lazy } from "react";
import QuizHistory from "../../pages/history/QuizHistory";

// use lazy for better code splitting, a.k.a. load faster
const ManageQuiz = lazy(() => import("../../pages/quiz/ManageQuiz"));
const ManageQuestion = lazy(() => import("../../pages/quiz/ManageQuestion"));
const QuizAnalytics = lazy(() => import("../../pages/quiz/QuizAnalytics"));
const QuestionAnalytics = lazy(() =>
  import("../../pages/quiz/QuestionAnalytics")
);

const quizRoutes = [
  {
    path: "/manage-quizzes", // the url
    component: ManageQuiz, // view rendered
    title: "Manage Quizzes",
    permission: ["Admin", "Player"],
    exact: true,
  },
  {
    path: "/manage-quizzes/:quizSlug/analytics", // the url
    component: QuizAnalytics, // view rendered
    title: "Quiz Analytics",
    permission: ["Admin", "Player"],
    exact: true,
  },
  {
    path: "/manage-quizzes/:quizSlug/questions", // the url
    component: ManageQuestion, // view rendered
    title: "Manage Quiz Questions",
    permission: ["Admin", "Player"],
    exact: true,
  },
  {
    path: "/quiz-history", // the url
    component: QuizHistory, // view rendered
    title: "Quiz History",
    permission: ["Player"],
  },
  {
    path: "/manage-quizzes/:quizSlug/Q:questionID/analytics/", // the url
    component: QuestionAnalytics, // view rendered
    title: "Question Analytics",
    permission: ["Admin", "Player"],
    exact: true,
  },
];

export default quizRoutes;
