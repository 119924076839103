import { lazy } from "react";
import ManageCategories from "../../pages/categories/ManageCategories";
// import QuizHistory from "../../pages/history/QuizHistory";

// use lazy for better code splitting, a.k.a. load faster
const ManageUsers = lazy(() => import("../../pages/accounts/ManageUser"));
const MyProfile = lazy(() => import("../../pages/accounts/MyProfile"));

const accountRoutes = [
  {
    path: "/manage-users", // the url
    component: ManageUsers, // view rendered
    title: "Manage Users",
    permission: ["Admin"],
    exact: true,
  },
  {
    path: "/manage-categories", // the url
    component: ManageCategories, // view rendered
    title: "Manage Categories",
    permission: ["Admin"],
    exact: true,
  },
  {
    path: "/app/my-profile", // the url
    component: MyProfile, // view rendered
    title: "My Profile",
  },
];

export default accountRoutes;
