import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const ManageEnquiry = lazy(() =>
  import("../../pages/miscellaneous/ManageEnquiry")
);
const ManageRank = lazy(() =>
  import("../../pages/miscellaneous/ranks/ManageRank")
);
const ManageQuizMisc = lazy(() =>
  import("../../pages/miscellaneous/quiz/ManageQuizMisc")
);

const miscRoutes = [
  {
    path: "/manage-enquiries/", // the url
    component: ManageEnquiry, // view rendered
    title: "Manage Enquiries",
    permission: ["Admin"],
    exact: true,
  },
  {
    path: "/manage-ranks/", // the url
    component: ManageRank, // view rendered
    title: "Manage Ranks",
    permission: ["Admin"],
    exact: true,
  },
  {
    path: "/manage-quizzes/:quizSlug/miscellaneous", // the url
    component: ManageQuizMisc, // view rendered
    title: "Manage Quiz Misc",
    permission: ["Admin"],
    exact: true,
  },
];

export default miscRoutes;
