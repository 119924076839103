import React from "react";

import { Group, Loader } from "@mantine/core";

const LoaderSuspense = () => {
  return (
    <Group
      position="center"
      className="d-flex align-items-center"
      sx={() => ({
        backgroundColor: "#161843",
        height: "100vh",
      })}
    >
      <Loader color="red" variant="bars" />
    </Group>
  );
};

export default LoaderSuspense;
