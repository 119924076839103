import React from "react";
import { AiFillEdit, AiFillDelete, AiOutlineDown } from "react-icons/ai";

import no_data from "../../assets/images/no_data.svg";

const CategoryAccordion = (props) => {
  const catData = props.catData;
  const prepareUpdate = props.prepareUpdate;
  const prepareDelete = props.prepareDelete;
  return (
    <>
      <div className="mb-3 landing-faq-card">
        <div
          className="card-header bg-white"
          id={"root-" + catData.id + "-title"}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h6
              className="mb-0"
              data-toggle="collapse"
              data-target={"#root-" + catData.id}
            >
              {catData.name}
            </h6>
            <div className="buttons-list ml-3">
              <div className="d-flex align-items-center ">
                <button
                  className="m-1 btn btn-sm btn-warning"
                  onClick={(e) => prepareUpdate(catData.api_url)}
                >
                  <AiFillEdit />
                </button>
                <button
                  className="m-1 btn btn-sm btn-danger"
                  onClick={(e) => prepareDelete(catData.api_url)}
                >
                  <AiFillDelete />
                </button>
                <button
                  className="m-1 btn btn-sm btn-primary"
                  // onClick={prepareDelete}
                  data-toggle="collapse"
                  data-target={"#root-" + catData.id}
                >
                  <AiOutlineDown />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          id={"root-" + catData.id}
          className="collapse"
          aria-labelledby={"root-" + catData.id + "-title"}
        >
          <div className="card-body">
            {catData.children.length > 0 ? (
              catData.children.map((childData, index) => (
                <CategoryAccordion
                  catData={childData}
                  prepareUpdate={prepareUpdate}
                  prepareDelete={prepareDelete}
                  key={index}
                />
              ))
            ) : (
              <div className="text-center">
                <img
                  src={no_data}
                  alt="No Data"
                  className="mx-auto "
                  style={{ width: "75px" }}
                />
                <p className="mt-3 mx-auto">No inner categories added yet!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryAccordion;
