import React from "react";
import { Link } from "react-router-dom";

import { BsFillMenuButtonWideFill } from "react-icons/bs";
// import { BsSearch } from "react-icons/bs";
// import { AiOutlineMenu } from "react-icons/ai";

import logo from "../../../assets/images/logo-light.svg";
import UserDropdown from "./UserDropdown";

const UHeader = () => {
  const sidebarToggle = () => {
    var body = document.body;
    if (
      body.classList.contains("sidebar-toggle-display") ||
      body.classList.contains("sidebar-absolute")
    ) {
      body.classList.toggle("sidebar-hidden");
    } else {
      body.classList.toggle("sidebar-icon-only");
    }
  };

  const offCanvasClick = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
  // $('[data-toggle="offcanvas"]').on("click", function() {
  //     $('.sidebar-offcanvas').toggleClass('active')
  //   });

  return (
    <>
      <div id="theme-settings" className="settings-panel">
        <i className="settings-close mdi mdi-close"></i>
        <p className="settings-heading">SIDEBAR SKINS TEST</p>
        <div className="sidebar-bg-options selected" id="sidebar-default-theme">
          <div className="img-ss rounded-circle bg-light border mr-3"></div>{" "}
          Default
        </div>
        <div className="sidebar-bg-options" id="sidebar-dark-theme">
          <div className="img-ss rounded-circle bg-dark border mr-3"></div> Dark
        </div>
        <p className="settings-heading mt-2">HEADER SKINS</p>
        <div className="color-tiles mx-0 px-4">
          <div className="tiles light"></div>
          <div className="tiles dark"></div>
        </div>
      </div>
      <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-between">
          <Link to="/">
            <span className="navbar-brand brand-logo-mini align-self-center d-lg-none">
              <img
                src={logo}
                alt="Aaja ko Nepal Logo"
                width={"50px"}
                height={"50px"}
              />
            </span>
          </Link>

          <button
            className="navbar-toggler navbar-toggler align-self-center mr-2"
            type="button"
            data-toggle="minimize"
            onClick={sidebarToggle}
          >
            <BsFillMenuButtonWideFill />
          </button>
          <ul className="navbar-nav">
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i className="mdi mdi-bell-outline"></i>
                <span className="count count-varient1">7</span>
              </a>
              <div
                className="dropdown-menu navbar-dropdown navbar-dropdown-large preview-list"
                aria-labelledby="notificationDropdown"
              >
                <h6 className="p-3 mb-0">Notifications</h6>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img
                      src="assets/images/faces/face4.jpg"
                      alt=""
                      className="profile-pic"
                    />
                  </div>
                  <div className="preview-item-content">
                    <p className="mb-0">
                      {" "}
                      Dany Miles{" "}
                      <span className="text-small text-muted">
                        commented on your photo
                      </span>
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img
                      src="assets/images/faces/face3.jpg"
                      alt=""
                      className="profile-pic"
                    />
                  </div>
                  <div className="preview-item-content">
                    <p className="mb-0">
                      {" "}
                      James{" "}
                      <span className="text-small text-muted">
                        posted a photo on your wall
                      </span>
                    </p>
                  </div>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <img
                      src="assets/images/faces/face2.jpg"
                      alt=""
                      className="profile-pic"
                    />
                  </div>
                  <div className="preview-item-content">
                    <p className="mb-0">
                      {" "}
                      Alex{" "}
                      <span className="text-small text-muted">
                        just mentioned you in his post
                      </span>
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <p className="p-3 mb-0">View all activities</p>
              </div>
            </li> */}
            {/* <li className="nav-item dropdown d-none d-sm-flex">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="messageDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i className="mdi mdi-email-outline"></i>
                <span className="count count-varient2">5</span>
              </a>
              <div
                className="dropdown-menu navbar-dropdown navbar-dropdown-large preview-list"
                aria-labelledby="messageDropdown"
              >
                <h6 className="p-3 mb-0">Messages</h6>
                <a className="dropdown-item preview-item">
                  <div className="preview-item-content flex-grow">
                    <span className="badge badge-pill badge-success">
                      Request
                    </span>
                    <p className="text-small text-muted ellipsis mb-0">
                      {" "}
                      Suport needed for user123{" "}
                    </p>
                  </div>
                  <p className="text-small text-muted align-self-start">
                    {" "}
                    4:10 PM{" "}
                  </p>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-item-content flex-grow">
                    <span className="badge badge-pill badge-warning">
                      Invoices
                    </span>
                    <p className="text-small text-muted ellipsis mb-0">
                      {" "}
                      Invoice for order is mailed{" "}
                    </p>
                  </div>
                  <p className="text-small text-muted align-self-start">
                    {" "}
                    4:10 PM{" "}
                  </p>
                </a>
                <a className="dropdown-item preview-item">
                  <div className="preview-item-content flex-grow">
                    <span className="badge badge-pill badge-danger">
                      Projects
                    </span>
                    <p className="text-small text-muted ellipsis mb-0">
                      {" "}
                      New project will start tomorrow{" "}
                    </p>
                  </div>
                  <p className="text-small text-muted align-self-start">
                    {" "}
                    4:10 PM{" "}
                  </p>
                </a>
                <h6 className="p-3 mb-0">See all activity</h6>
              </div>
            </li> */}
            {/* <li className="nav-item nav-search border-0 ml-1 ml-md-3 ml-lg-5 d-none d-md-flex">
              <form className="nav-link form-inline mt-2 mt-md-0">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <BsSearch />
                    </span>
                  </div>
                </div>
              </form>
            </li> */}
          </ul>
          <ul className="navbar-nav navbar-nav-right ml-lg-auto">
            {/* <li className="nav-item dropdown d-none d-xl-flex border-0">
              <a
                className="nav-link dropdown-toggle"
                id="languageDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <i className="mdi mdi-earth"></i> English
              </a>
              <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="languageDropdown"
              >
                <a className="dropdown-item" href="#">
                  {" "}
                  French{" "}
                </a>
                <a className="dropdown-item" href="#">
                  {" "}
                  Spain{" "}
                </a>
                <a className="dropdown-item" href="#">
                  {" "}
                  Latin{" "}
                </a>
                <a className="dropdown-item" href="#">
                  {" "}
                  Japanese{" "}
                </a>
              </div>
            </li> */}
            <UserDropdown />
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
            onClick={offCanvasClick}
          >
            <BsFillMenuButtonWideFill />
          </button>
        </div>
      </nav>
    </>
  );
};

export default UHeader;
