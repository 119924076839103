import React from "react";
// import ios from "../../assets/images/ios.svg";
// import android from "../../assets/images/android.svg";
// import app_1 from "../../assets/images/app_1.png";
import hero from "../../assets/images/final-hero.svg";
import Nav from "./nav";

const Header = ({ getStarted }) => {
  return (
    <header className="foi-header landing-header mb-lg-0">
      <div className="container">
        <Nav />
        <div className="header-content">
          <div className="row">
            <div className="col-md-6 my-3 my-sm-0">
              <h1>Quizzes at your fingertips</h1>
              {/* <h1 className="font-weight-light">
                आउनुहोस् र आफ्नो विचार प्रस्तुत गर्नुहोस्
              </h1> */}
              <p className="text-dark mt-3 lead">
                Come, Let's{" "}
                <span className="font-weight-bold text-danger">
                  play | learn{" "}
                </span>{" "}
                together
              </p>
              <button
                className="btn btn-primary my-3"
                onClick={() => getStarted()}
              >
                Get Started
              </button>
              {/* <div className="my-2">
                <p className="header-app-download-title">GET OUR MOBILE APP</p>
              </div>
              <div>
                <button className="btn btn-app-download mr-2">
                  <img src={ios} alt="App store" />
                </button>
                <button className="btn btn-app-download">
                  <img src={android} alt="play store" />
                </button>
              </div> */}
            </div>
            <div className="col-md-6">
              {/* <img src={app_1} alt="app" width="388px" className="img-fluid" /> */}
              <img src={hero} alt="Hero" className="hero-img" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
