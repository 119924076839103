import { Link } from "react-router-dom";

// import { AiOutlineUsergroupAdd as UsersIcon } from "react-icons/ai";
import {
  MdOutlineCategory as CategoryIcon,
  MdSpaceDashboard as CMSIcon,
  MdOutlineContactPhone as EnquiryIcon,
} from "react-icons/md";
import { ImHome, ImQuestion, ImUsers, ImHistory } from "react-icons/im";
import { GiRank3 as RankIcon } from "react-icons/gi";
import styled from "@emotion/styled";

import logo from "../../../assets/images/akn.svg";
import {
  withAdminRole,
  withPlayerRole,
  withUserRole,
} from "../../../utils/withRole";

const AdminLI = withAdminRole(styled.li``);
const UserLI = withUserRole(styled.li``);
const PlayerLI = withPlayerRole(styled.li``);

const Sidebar = () => {
  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center mb-3">
          <Link to="/" className="sidebar-brand brand-logo">
            <img src={logo} alt="logo" />
          </Link>
          <Link to="/" className="sidebar-brand brand-logo-mini pl-4 pt-3">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <ul className="nav pt-3">
          {/* <li className="nav-item nav-profile">
            <a href="#" className="nav-link">
              <div className="nav-profile-image">
                <img
                  src="https://images.unsplash.com/photo-1647790355082-7615dd4078a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                  alt="profile"
                  className="object-cover"
                />
                <span className="login-status online"></span>
              </div>
              <div className="nav-profile-text d-flex flex-column pr-3">
                <span className="font-weight-medium mb-2">Henry Klein</span>
                <span className="font-weight-normal">$8,753.00</span>
              </div>
              <span className="badge badge-danger text-white ml-3 rounded">
                3
              </span>
            </a>
          </li> */}
          <li className="nav-item">
            <Link to="/app" className="nav-link">
              <ImHome fontSize={24} />
              <span className="menu-title ml-2">Dashboard</span>
            </Link>
          </li>
          <AdminLI className="nav-item">
            <Link to="/manage-users" className="nav-link">
              <ImUsers
                className="menu-icon mr-2 font-weight-bold"
                fontSize={25}
              />
              <span className="menu-title">Users</span>
            </Link>
          </AdminLI>
          <AdminLI className="nav-item">
            <Link to="/manage-categories" className="nav-link">
              <CategoryIcon
                className="menu-icon mr-2 font-weight-bold"
                fontSize={25}
              />
              <span className="menu-title">Categories</span>
            </Link>
          </AdminLI>
          <UserLI className="nav-item">
            <Link to="/manage-quizzes" className="nav-link">
              <ImQuestion
                className="menu-icon mr-2 font-weight-bold"
                fontSize={25}
              />
              <span className="menu-title">Quizzes</span>
            </Link>
          </UserLI>
          <PlayerLI className="nav-item">
            <Link to="/quiz-history" className="nav-link">
              <ImHistory
                className="menu-icon mr-2 font-weight-bold"
                fontSize={25}
              />
              <span className="menu-title">Quiz History</span>
            </Link>
          </PlayerLI>
          {/* <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title">Basic UI Elements</span>
              <i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link to="/buttons" className="nav-link">
                    Buttons
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/dropdowns" className="nav-link">
                    Dropdowns
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="pages/ui-features/typography.html"
                  >
                    Typography
                  </a>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="pages/icons/mdi.html">
              <i className="mdi mdi-contacts menu-icon"></i>
              <span className="menu-title">Icons</span>
            </a>
          </li> */}
          {/* <li className="nav-item">
            <a className="nav-link" href="pages/charts/chartjs.html">
              <i className="mdi mdi-chart-bar menu-icon"></i>
              <span className="menu-title">Charts</span>
            </a>
          </li> */}
          {/* <hr className="border-dark border-top" /> */}
          <AdminLI className="nav-item border-top">
            <span className="nav-link" href="#">
              <span className="menu-title font-weight-bold">Extras</span>
            </span>
          </AdminLI>
          <AdminLI className="nav-item">
            <Link to="/manage-ranks" className="nav-link">
              <RankIcon
                className="menu-icon mr-2 font-weight-bold"
                fontSize={25}
              />
              <span className="menu-title">Ranks</span>
            </Link>
          </AdminLI>
          <AdminLI className="nav-item">
            <Link to="/cms" className="nav-link">
              <CMSIcon
                className="menu-icon mr-2 font-weight-bold"
                fontSize={25}
              />
              <span className="menu-title">CMS</span>
            </Link>
          </AdminLI>
          <AdminLI className="nav-item">
            <Link to="/manage-enquiries" className="nav-link">
              <EnquiryIcon
                className="menu-icon mr-2 font-weight-bold"
                fontSize={25}
              />
              <span className="menu-title">Enquiries</span>
            </Link>
          </AdminLI>
          {/* <li className="nav-item sidebar-actions">
            <div className="nav-link">
              <div className="mt-4">
                <div className="border-none">
                  <p className="text-black">Notification</p>
                </div>
                <ul className="mt-4 pl-0">
                  <li>Sign Out</li>
                </ul>
              </div>
            </div>
          </li> */}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
