import toast from "react-hot-toast";
import useSWR from "swr";

import { fetcher } from "./axios";

const useLoadMore = (url, limit = 6, offset = 0) => {
  const { data: initialData, error } = useSWR(
    `${url}${
      url.includes("?") ? "&" : "?"
    }limit=${limit}&offset=${offset}`,
    fetcher
  );

  error && toast.error("Couldn't load data!");

  //   console.log(initialData);

  return {
    data: initialData?.results,
    count: initialData?.count,
    loadNext: initialData?.next,
    loadPrevious: initialData?.previous,
  };
};

export default useLoadMore;
