import { createSlice } from "@reduxjs/toolkit";

const initialState = { title: "", categories: [], types: [] };

const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    setQuizSearch(state, action) {
      state.title = action.payload.title;
      state.categories = action.payload.categories;
      state.types = action.payload.types;
    },

    resetQuizSearch(state) {
      state.title = "";
      state.categories = [];
      state.types = [];
    },
  },
});

export default searchSlice;

export { initialState };
