import React from "react";

import useSWR from "swr";
import { Divider } from "@mantine/core";

import { fetcher } from "../../../utils/axios";
import CategoryCard from "./categoryCard";

const SearchQuiz = React.lazy(() => import("../../explore/SearchQuiz"));

const RootCategories = () => {
  const getCategoriesUrl = `/categories/root/list-category/`;
  const getRootCategories = useSWR(getCategoriesUrl, fetcher);

  // console.log(getRootCategories?.data);

  return (
    <>
      <div className="row">
        {getRootCategories?.data?.map((catData, index) => (
          <CategoryCard catData={catData} color="danger" key={index} />
        ))}
      </div>

      <div className="row">
        <div className="col-lg-6 mx-auto">
          <Divider
            size="md"
            my="md"
            label={<h6>OR</h6>}
            labelPosition="center"
          />
        </div>
      </div>
      <SearchQuiz />
    </>
  );
};

export default RootCategories;
