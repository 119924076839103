import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const Hub = lazy(() => import("../../pages/cms/Hub"));
const ManageIOTD = lazy(() => import("../../pages/cms/iotd/ManageIOTD"));

const accountRoutes = [
  {
    path: "/cms", // the url
    component: Hub, // view rendered
    title: "CMS Hub",
    permission: ["Admin"],
    exact: true,
  },
  {
    path: "/cms/manage-iotd/", // the url
    component: ManageIOTD, // view rendered
    title: "Manage IOTD",
    permission: ["Admin"],
  },
];

export default accountRoutes;
