import React from "react";

import { Loader } from "@mantine/core";

import naSVG from "../../../assets/images/not-available.svg";

const NotAvailable = ({
  label,
  image = naSVG,
  showLoader = false,
  showThumbnail = true,
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-4 mx-auto text-center">
          {showThumbnail && (
            <img src={image} alt="Not Available" className="img-fluid" />
          )}
          <p>{label}</p>
          {showLoader && <Loader variant="dots" color="red" />}
        </div>
      </div>
    </div>
  );
};

export default NotAvailable;
