import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
            Copyright © Aaja ko Nepal 2022
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            An initiative for a{" "}
            <a
              href="https://www.bootstrapdash.com/"
              target="_blank"
              rel="noreferrer"
            >
              Better
            </a>{" "}
            Nepal
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
