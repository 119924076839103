import { lazy } from "react";

import accountRoutes from "./appRoutes/accountRoutes";
import quizRoutes from "./appRoutes/quizRoutes";
import cmsRoutes from "./appRoutes/cmsRoutes";
import miscRoutes from "./appRoutes/miscRoutes";

// use lazy for better code splitting, a.k.a. load faster
const Home = lazy(() => import("../dashboard/pages/home"));

const protectedRoutes = [
  {
    path: "/app", // the url
    component: Home, // view rendered
    exact: true,
  },

  ...accountRoutes,
  ...quizRoutes,
  ...cmsRoutes,
  ...miscRoutes,
];

export default protectedRoutes;
