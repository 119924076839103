import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Modal, Kbd } from "@mantine/core";
import useSWR, { useSWRConfig } from "swr";
import toast from "react-hot-toast";

import axiosService, { fetcher } from "../../utils/axios";
import AddCategory from "./AddCategory";
import UpdateCategory from "./UpdateCategory";
import RemoveCategory from "../accounts/RemoveConfirm";
import CategoryAccordion from "./CategoryAccordion";

const ManageCategories = () => {
  const getCategoriesUrl = `/categories/category/`;
  const getCategories = useSWR(getCategoriesUrl, fetcher);
  const { mutate } = useSWRConfig();
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("Update");
  const [actionURL, setActionURL] = useState("");

  const openModal = () => {
    setShowModal(true);
  };

  const prepareAddCategory = () => {
    setModalAction("New");
    openModal();
  };

  const prepareUpdate = (updateURL) => {
    setModalAction("Update");
    openModal();
    setActionURL(updateURL);
  };

  const prepareDelete = (deleteUrl) => {
    setModalAction("Delete");
    openModal();
    setActionURL(deleteUrl);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const mutateCategories = () => {
    mutate(getCategoriesUrl);
  };

  const handleRemove = () => {
    // Validating if the action URL is empty
    if (actionURL === "") return;
    axiosService
      .delete(actionURL)
      .then((res) => {
        mutateCategories();
        toast("Category Removed!");
      })
      .catch((err) => {
        console.log(err);
        toast("Cannot remove!");
      });
    closeModal();
  };

  return (
    <>
      <Modal
        centered
        opened={showModal}
        onClose={closeModal}
        title={<h3>{modalAction} Category</h3>}
      >
        {/* Modal content */}
        {modalAction === "New" ? (
          <AddCategory closeModal={closeModal} mutate={mutateCategories} />
        ) : modalAction === "Update" ? (
          <UpdateCategory
            url={actionURL}
            closeModal={closeModal}
            mutate={mutateCategories}
          />
        ) : (
          <RemoveCategory
            closeModal={closeModal}
            type={"category"}
            handleRemove={handleRemove}
          />
        )}
      </Modal>

      <div className="page-header">
        <h3 className="page-title">Manage Categories</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Manage Categories
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-10 col-12 mx-auto grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-3">
                <button
                  type="button"
                  className="btn py-2 btn-info"
                  onClick={prepareAddCategory}
                >
                  Add Category
                </button>
                <p>
                  Total: <Kbd>{getCategories?.data?.length}</Kbd>
                </p>
              </div>
              <div className="table-responsive">
                <div className="card-body">
                  {getCategories?.data?.map((catData, index) => (
                    <CategoryAccordion
                      catData={catData}
                      prepareUpdate={prepareUpdate}
                      prepareDelete={prepareDelete}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCategories;
