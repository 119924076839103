import React from "react";

import Footer from "../components/footer/footer";
import UHeader from "../components/header/uheader";
import Sidebar from "../components/sidebar/sidebar";

// import "../assets/vendors/mdi/css/materialdesignicons.min.css";

const UBaseTheme = React.lazy(() => import("./UBaseTheme"));

// const ThemeSelector = React.lazy(() => import("../../ThemeSelector"));

const UBaseLayout = ({ children }) => {
  // const dispatch = useDispatch();
  // const theme = useSelector((state) => state.theme);

  // useEffect(() => {
  //   // console.log(theme);
  //   if (theme.current === "base") {
  //     dispatch(themeSlice.actions.setUBaseTheme());
  //   }
  //   console.log("User base Page ", theme);
  // });

  return (
    // <React.Suspense fallback={<></>}>
    <div className="App dstyle">
      {/* <Helmet>
          <style>{`${ustyle}`}</style>
        </Helmet> */}
      <UBaseTheme />
      <div className="container-scroller">
        <Sidebar />
        <div className="container-fluid page-body-wrapper">
          <UHeader />
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              <>{children}</>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
    // </React.Suspense>
  );
};

export default UBaseLayout;
