import React from "react";

import { AiOutlineInfoCircle } from "react-icons/ai";

const RemoveConfirm = ({ closeModal, type, handleRemove }) => {
  return (
    <div className="justify-content-center">
      <div className="bg-white mb-2">
        <p className="font-weight-bold h5">
          Sure you want to remove this {type}?
        </p>
        <small className="text-muted font-weight-light ml-3">
          <AiOutlineInfoCircle className="mr-2" />
          Please note that you cannot undo this operation.
        </small>
      </div>
      <div className="card-body px-sm-4 mb-2 pt-1 pb-0">
        <div className="row justify-content-end no-gutters">
          <div className="col-auto">
            <button
              onClick={handleRemove}
              type="button"
              className="btn btn-danger px-3 mx-1"
            >
              Confirm
            </button>
          </div>
          <div className="col-auto">
            <button
              onClick={closeModal}
              type="button"
              className="btn btn-light text-muted mx-1"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveConfirm;
