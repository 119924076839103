import { lazy } from "react";

// import ChildCategories from "../components/categories/childCategories";

// use lazy for better code splitting, a.k.a. load faster
const Play = lazy(() => import("../pages/public/Play"));
const Contact = lazy(() => import("../pages/public/Contact"));
const Register = lazy(() => import("../pages/Register"));
const About = lazy(() => import("../pages/About"));
const Login = lazy(() => import("../pages/Login"));
const Privacy = lazy(() => import("../pages/PrivacyPolicy"));
const CategoryDetail = lazy(() =>
  import("../pages/categories/public/CategoryDetail")
);
const Explore = lazy(() => import("../pages/explore/Explore"));
const SearchResult = lazy(() => import("../pages/explore/SearchResult"));
const PollResultMain = lazy(() => import("../pages/public/PollResultMain"));
const CompetitionResultMain = lazy(() => import("../pages/public/CompetitionResultMain"));

const publicRoutes = [
  {
    path: "/register", // the url
    component: Register, // view rendered
    title: "Sign up",
  },
  {
    path: "/play", // the url
    component: Play, // view rendered
    exact: true,
    title: "Play Quizzes",
  },

  {
    path: "/search-quiz/", // the url
    component: SearchResult, // view rendered
    title: "Search Results",
    exact: true,
  },

  {
    path: "/public-poll-results", // the url
    component: PollResultMain, // view rendered
    exact: true,
    title: "Public Poll Results",
  },
  
  {
    path: "/public-competition-results", // the url
    component: CompetitionResultMain, // view rendered
    exact: true,
    title: "Competition Results",
  },

  {
    path: "/about", // the url
    component: About, // view rendered
    title: "About Us",
  },
  {
    path: "/contact", // the url
    component: Contact, // view rendered
    title: "Contact us",
  },
  {
    path: "/explore", // the url
    component: Explore, // view rendered
    title: "Explore",
  },
  {
    path: "/login", // the url
    component: Login, // view rendered
    title: "Login",
  },
  {
    path: "/privacy", // the url
    component: Privacy, // view rendered
    title: "Privacy Policies",
  },
  {
    path: "/category/:catId/detail/",
    component: CategoryDetail,
    title: "Category Detail",
  },
];

export default publicRoutes;
