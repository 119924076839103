import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiBlock } from "react-icons/bi";
import { Card, List, Text, ThemeIcon } from "@mantine/core";
import {
  BsQuestionDiamondFill,
  BsFillCheckCircleFill as CircleCheck,
} from "react-icons/bs";

const QuizQuestions = ({ questions, settings }) => {
  return (
    <>
      <Slider {...settings} className="mx-4">
        {questions?.map((question, index) => (
          <Card shadow="sm" className="px-3" key={index}>
            <div className="d-flex align-items-center mb-3">
              <BsQuestionDiamondFill
                className="mr-2 text-danger"
                fontSize={25}
              />
              <Text size="lg" className="font-weight-bold">
                {question?.text}
              </Text>
            </div>

            <List
              spacing="xs"
              size="sm"
              center
              icon={
                <ThemeIcon color="gray" size={24} radius="xl">
                  <BiBlock size={16} />
                </ThemeIcon>
              }
            >
              {question?.options?.map((option, index) => (
                <List.Item
                  icon={
                    option.is_correct && (
                      <ThemeIcon color="teal" size={24} radius="xl">
                        <CircleCheck size={16} />
                      </ThemeIcon>
                    )
                  }
                  key={index}
                >
                  {option.text}
                </List.Item>
              ))}
            </List>
          </Card>
        ))}
      </Slider>
    </>
  );
};

export default QuizQuestions;
