import React from "react";
import { Link } from "react-router-dom";

import { useScrollIntoView } from "@mantine/hooks";
import { GiBinoculars } from "react-icons/gi";
import { BiRightTopArrowCircle } from "react-icons/bi";
import useSWR from "swr";
import toast from "react-hot-toast";

// import "../assets/css/style.css"
import "../assets/vendors/fontawesome-free/css/all.min.css";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import RootCategories from "./categories/public/rootCategories";
import { fetcher } from "../utils/axios";
import useDocTitle from "../utils/useDocTitle";
import PublicCompResult from "../components/index/PublicCompResult";

const BaseTheme = React.lazy(() => import("../layouts/BaseTheme"));
const Features = React.lazy(() => import("../components/index/Features"));
const HotQuestions = React.lazy(() =>
  import("../components/index/HotQuestions")
);
const IOTD = React.lazy(() => import("../components/index/IOTD"));
const PublicPollResult = React.lazy(() =>
  import("../components/index/PublicPollResult")
);
const RecentPolls = React.lazy(() => import("../components/index/RecentPoll"));

const Index = () => {
  useDocTitle("AKN | Home");
  const { scrollIntoView, targetRef } = useScrollIntoView({ offset: 50 });
  const { data: pageData, error: getDataErr } = useSWR(
    "/core/index-page/",
    fetcher
  );

  // console.log(pageData);
  getDataErr && toast.error("Could not load page data!");

  const scrollToHQ = () => {
    scrollIntoView({ alignment: "center" });
  };

  return (
    <div className="App bstyle">
      <BaseTheme />
      <Header getStarted={scrollToHQ} />
      <Features winners={pageData?.winners_list} />
      <HotQuestions scrollRef={targetRef} />
      {pageData?.recent_polls && <RecentPolls polls={pageData.recent_polls} />}

      <section className="py-4">
        <div className="container">
          <h2 className="text-primary">
            Explore Quizzes <GiBinoculars />
          </h2>
          <p className="text-muted mb-4">
            Let's test out how much you know. Explore thouands of quizzes today
          </p>
          <RootCategories />
          {/* <div className="row">
            <div className="col-lg-4 mb-4">
              <div className="card pricing-card border-primary active">
                <div className="card-body">
                  <h3>Popular</h3>
                  <h3 className="text-primary">$23.00</h3>
                  <p className="payment-period">Per month</p>
                  <p className="mb-4">
                    Thank you for your very professional and prompt response.
                  </p>
                  <button className="btn btn-primary btn-rounded">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card pricing-card border-success">
                <div className="card-body">
                  <h3>Enterprise</h3>
                  <h3 className="text-success">$40.00</h3>
                  <p className="payment-period">Per month</p>
                  <p className="mb-4">
                    Thank you for your very professional and prompt response.
                  </p>
                  <button className="btn btn-outline-success btn-rounded">
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <IOTD data={pageData?.iotd_list} />
      {pageData?.lpp_data && pageData.lpp_data.length > 0 && (
        <>
          <PublicPollResult polls={pageData?.lpp_data} />
          <div className="row justify-content-center">
            <Link to="/public-poll-results" className="btn btn-secondary mb-3">
              <span className="mr-1">View more</span>
              <BiRightTopArrowCircle />
            </Link>
          </div>
        </>
      )}
      {pageData?.lpc_data && pageData.lpc_data.length > 0 && (
        <>
          <PublicCompResult competitions={pageData?.lpc_data} />
          <div className="row justify-content-center">
            <Link
              to="/public-competition-results"
              className="btn btn-secondary mb-3"
            >
              <span className="mr-1">See more</span>
              <BiRightTopArrowCircle />
            </Link>
          </div>
        </>
      )}
      {/* <section className="py-5 mb-5">
        <div className="container">
          <h2>Satisfied Users</h2>
          <p className="text-muted mb-5">
            Thank you for your very professional and prompt response. I wished I
            had found you before{" "}
          </p>
          <div className="row">
            <div className="col-md-4 foi-review mb-5 mb-md-0">
              <div className="foi-rating">
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
              </div>
              <h5 className="foi-review-heading">Great support available</h5>
              <p className="foi-review-content">
                Thank you for your very professional and prompt response. I
                wished I had found you before I spent money on a competitors
                theme.
              </p>
              <div className="media foi-review-user">
                <img src={avatar_11} alt="user" className="avatar" />
                <div className="media-body">
                  <h6 className="mb-0">Amarachi Nkechi</h6>
                  <p>UX Designer</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 foi-review mb-5 mb-md-0">
              <div className="foi-rating">
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
              </div>
              <h5 className="foi-review-heading">Great support available</h5>
              <p className="foi-review-content">
                Thank you for your very professional and prompt response. I
                wished I had found you before I spent money on a competitors
                theme.
              </p>
              <div className="media foi-review-user">
                <img src={avatar_12} alt="user" className="avatar" />
                <div className="media-body">
                  <h6 className="mb-0">Margje Jutten</h6>
                  <p>Developer</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 foi-review mb-5 mb-md-0">
              <div className="foi-rating">
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
                <span className="fas fa-star checked"></span>
              </div>
              <h5 className="foi-review-heading">Great support available</h5>
              <p className="foi-review-content">
                Thank you for your very professional and prompt response. I
                wished I had found you before I spent money on a competitors
                theme.
              </p>
              <div className="media foi-review-user">
                <img src={avatar_13} alt="user" className="avatar" />
                <div className="media-body">
                  <h6 className="mb-0">Monica Böttger</h6>
                  <p>UX Designer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="py-5 mb-5">
        <div className="container">
          <h2>FAQs</h2>
          <p className="section-subtitle">Frequently Asked Questions</p>
          <div className="row">
            <div className="col-lg-6">
              <div className="card mb-3 landing-faq-card">
                <div className="card-header bg-white" id="faqOneTitle">
                  <a
                    href="#faqOneCollapse"
                    className="d-flex align-items-center"
                    data-toggle="collapse"
                  >
                    <h6 className="mb-0">What is Foi app?</h6>{" "}
                    <i className="far fa-plus-square ml-auto"></i>
                  </a>
                </div>
                <div
                  id="faqOneCollapse"
                  className="collapse"
                  aria-labelledby="faqOneTitle"
                >
                  <div className="card-body">
                    <p className="mb-0 text-gray">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-3 landing-faq-card">
                <div className="card-header bg-white" id="faqTwoTitle">
                  <a
                    href="#faqTwoCollapse"
                    className="d-flex align-items-center"
                    data-toggle="collapse"
                  >
                    <h6 className="mb-0">Why should I use Foi app?</h6>{" "}
                    <i className="far fa-plus-square ml-auto"></i>
                  </a>
                </div>
                <div
                  id="faqTwoCollapse"
                  className="collapse"
                  aria-labelledby="faqTwoTitle"
                >
                  <div className="card-body">
                    <p className="mb-0 text-gray">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-3 landing-faq-card">
                <div className="card-header bg-white" id="faqThreeTitle">
                  <a
                    href="#faqThreeCollapse"
                    className="d-flex align-items-center"
                    data-toggle="collapse"
                  >
                    <h6 className="mb-0">How can I use Foi app?</h6>{" "}
                    <i className="far fa-plus-square ml-auto"></i>
                  </a>
                </div>
                <div
                  id="faqThreeCollapse"
                  className="collapse"
                  aria-labelledby="faqThreeTitle"
                >
                  <div className="card-body">
                    <p className="mb-0 text-gray">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-3 landing-faq-card">
                <div className="card-header bg-white" id="faqFourTitle">
                  <a
                    href="#faqFourCollapse"
                    className="d-flex align-items-center"
                    data-toggle="collapse"
                  >
                    <h6 className="mb-0">Who will see my updates?</h6>{" "}
                    <i className="far fa-plus-square ml-auto"></i>
                  </a>
                </div>
                <div
                  id="faqFourCollapse"
                  className="collapse"
                  aria-labelledby="faqFourTitle"
                >
                  <div className="card-body">
                    <p className="mb-0 text-gray">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-3 landing-faq-card">
                <div className="card-header bg-white" id="faqFiveTitle">
                  <a
                    href="#faqFiveCollapse"
                    className="d-flex align-items-center"
                    data-toggle="collapse"
                  >
                    <h6 className="mb-0">Can people see my connections?</h6>{" "}
                    <i className="far fa-plus-square ml-auto"></i>
                  </a>
                </div>
                <div
                  id="faqFiveCollapse"
                  className="collapse"
                  aria-labelledby="faqFiveTitle"
                >
                  <div className="card-body">
                    <p className="mb-0 text-gray">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card mb-3 landing-faq-card">
                <div className="card-header bg-white" id="faqSixTitle">
                  <a
                    href="#faqSixCollapse"
                    className="d-flex align-items-center"
                    data-toggle="collapse"
                  >
                    <h6 className="mb-0">
                      Being a user, what all rights I have?
                    </h6>{" "}
                    <i className="far fa-plus-square ml-auto"></i>
                  </a>
                </div>
                <div
                  id="faqSixCollapse"
                  className="collapse"
                  aria-labelledby="faqSixTitle"
                >
                  <div className="card-body">
                    <p className="mb-0 text-gray">
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};

export default Index;
