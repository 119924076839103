import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Modal, Kbd, Text } from "@mantine/core";
import { BsClockHistory } from "react-icons/bs";

import { TextLinkNormal } from "../../dashboard/components/misc/Extra";
import NotAvailable from "../../dashboard/components/misc/NotAvailable";
import ResultSlider from "./ResultSlider";
import historySVG from "../../assets/images/history.svg";
import useLoadMore from "../../utils/useLoadMore";

const QuizHistory = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState("View");
  const [answers, setAnswers] = useState("");
  const [result, setResult] = useState("");
  const getSubmissionsUrl = "/quiz/get-quiz-submissions/";
  const [limit, setLimit] = useState(10);
  const { data: initialData, loadNext } = useLoadMore(getSubmissionsUrl, limit);

  const [submissions, setSubmissions] = useState(null);

  // const { data: submissions, error: getSubmissionError } = useSWR(
  //   getSubmissionsUrl,
  //   fetcher
  // );

  // getSubmissionError && toast.error("Could not load statistics!");

  // console.log(submissions);

  useEffect(() => {
    if (initialData) {
      setSubmissions(initialData);
    }
  }, [initialData]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const prepareViewResults = (index) => {
    setModalAction("View");
    setAnswers(submissions[index]?.answers);
    setResult(submissions[index]);
    // console.log(detailURL);
    openModal();
  };

  const notPublishView = () => {
    setModalAction("Not Published");
    openModal();
  };

  return (
    <>
      <Modal
        centered
        size="xl"
        opened={showModal}
        onClose={closeModal}
        title={<h3>{modalAction} Results</h3>}
        className="overflow-x-hidden"
      >
        {/* Modal content */}
        {modalAction === "View" ? (
          <>
            <div className="row">
              {answers && answers.length > 0 ? (
                <ResultSlider answers={answers} />
              ) : (
                <NotAvailable label="No answers received!" />
              )}
            </div>

            <div className="row justify-content-between px-5 mt-3">
              <p>
                Score:{" "}
                <span className="font-weight-bold">{result?.results}</span>
              </p>
              <Button
                onClick={closeModal}
                variant="light"
                color="primary"
                className="m-1"
              >
                Done
              </Button>
            </div>
          </>
        ) : (
          // <ViewResult url={actionURL} closeModal={closeModal} />
          <div className="text-center">
            <NotAvailable label="Result Not Published Yet! Please check again later" />
          </div>
        )}
      </Modal>

      <div className="page-header">
        <h3 className="page-title">Quiz History</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/app">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              History
            </li>
          </ol>
        </nav>
      </div>

      <div className="card table-responsive p-3">
        <p className="h3 mb-3 mx-3">
          <BsClockHistory />
          <span className="ml-2">Recent Plays </span>
        </p>
        {submissions &&
          (submissions.length > 0 ? (
            <>
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Quiz</th>
                  <th>Date</th>
                  <th>Result</th>
                  <th>Points Collected</th>
                </tr>
              </thead>
              <tbody>
                {submissions.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Text
                        className="font-weight-bold text-custom-primary"
                        size="sm"
                        lineClamp={1}
                        style={{
                          minWidth: "250px",
                          maxWidth: "300px",
                          whiteSpace: "normal",
                        }}
                      >
                        {item?.quiz?.title}
                      </Text>
                    </td>
                    <td>{item.submitted_at}</td>
                    <td>
                      <TextLinkNormal
                        onClick={() =>
                          item.quiz.quiz_type !== "Poll" &&
                          item.quiz.publish_result
                            ? prepareViewResults(index)
                            : notPublishView()
                        }
                      >
                        {item.quiz.quiz_type !== "Poll" &&
                        item.quiz.publish_result ? (
                          <Kbd className="bg-secondary text-dark">
                            {item.results}
                          </Kbd>
                        ) : (
                          "N/A"
                        )}
                      </TextLinkNormal>
                    </td>
                    <td className="font-weight-bold text-custom-danger">
                      {item.points_collected ?? "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
              
            </table>
            {loadNext && (
              <div className="row justify-content-center my-3">
                <button
                  onClick={() => setLimit(limit + 10)}
                  className="btn btn-secondary"
                >
                  Load more
                </button>
              </div>
            )}
            </>
          ) : (
            <NotAvailable label="No quiz plays recorded yet!" />
          ))}
      </div>

      {submissions && submissions.length > 0 && (
        <div className="container mt-3">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 mx-auto">
              <img
                src={historySVG}
                alt="Player History SVG"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuizHistory;
