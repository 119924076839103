import React from "react";
import {
  AiFillDelete,
  AiFillEdit,
  AiFillCaretLeft,
  AiFillCaretRight,
} from "react-icons/ai";

const EditButton = (props) => {
  return (
    <button {...props} className="btn btn-sm btn-warning px-2 m-1">
      <AiFillEdit />
    </button>
  );
};

const RemoveButton = (props) => {
  return (
    <button {...props} className="btn btn-sm btn-danger px-2 m-1">
      <AiFillDelete />
    </button>
  );
};

const CarouselArrowLeft = (props) => (
  <AiFillCaretLeft
    className={props.className}
    style={{
      ...props.style,
      display: "block",
      color: "#161843",
    }}
    onClick={props.onClick}
  />
);

const CarouselArrowRight = (props) => (
  <AiFillCaretRight
    className={props.className}
    style={{
      ...props.style,
      display: "block",
      color: "#161843",
    }}
    onClick={props.onClick}
  />
);

export { EditButton, RemoveButton, CarouselArrowLeft, CarouselArrowRight };
